

@mixin jlpButton {
  background: $orange-bg;
  transition: all 0.3s ease-out;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);

  &:hover {
    background: lighten($orange-bg, 5%);
    box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18),
      0 4px 15px 0 rgba(0, 0, 0, 0.15);
  }
}

@mixin themedButton($theme) {
  $primary-bg: $orange-bg;
  $light-bg: $orange-light-bg;

  @if $theme == 'mind' {
    $primary-bg: $mind-primary-bg;
    $light-bg: $mind-light-bg;
  } @else if $theme == 'body' {
    $primary-bg: $body-primary-bg;
    $light-bg: $body-light-bg;
  } @else if $theme == 'life' {
    $primary-bg: $life-primary-bg;
    $light-bg: $life-light-bg;
  } @else if $theme == 'soul' {
    $primary-bg: $soul-primary-bg;
    $light-bg: $soul-light-bg;
  }

  .container {
    .product-price {
      border-top: 5px solid $primary-bg;
      border-bottom: 5px solid $primary-bg;
      background: $light-bg;
    }
  }

  button {
    width: 100%;
    background-color: $primary-bg;
    &:hover,
    &:focus {
      background-color: darken($primary-bg, 10%);
    }
  }
}
