#modal {
  position: fixed;
  left: 0;
  top: 0;
  padding: 0;

  width: 100%;
  height: 100%;
  z-index: $modal-z-index + 1;
  overflow: auto;

  &.video {
    .close {
      z-index: 1;
      @media #{$large-up} {
        transform: translateY(-110%);
      }
    }
    .modal-content {
      border: none;
      .modal-container {
        margin: 0;
      }
    }
    .body {
      position: relative;
      width: 100%;
      padding-bottom: #{100% * 9 / 16};
      background-image: url(../images/video-placeholder.png);
      background-position: center;
      background-size: cover;

      iframe {
        position: absolute;
        width: 100%;
        height: 100%;
        border: none;
      }
    }
  }

  .checkbox-with-text input {
    margin: 0;
  }

  .modal-content {
    border: 2px solid black;
    border-radius: 5px;
    position: relative;
    background-color: #ffffff;
    width: 90%;
    margin: 5% auto;
    max-width: 800px;
    //min-height:20%; // Override main min-width of .content on 100%

    .switch {
      display: flex;
      align-items: center;

      span {
        flex: 1;
      }
    }

    h3.switched {
      display: flex;

      span {
        flex: 1;
      }
      .switch {
        display: inline-block;
        margin-bottom: 0;
      }
    }

    &.large {
      max-width: 1100px;
      margin: 2% auto;
    }
    .modal-container {
      margin: 5%;

      .body {
        // min-height: 90%;
      }

      .profile-modal {
        .fields-container {
          margin-bottom: 1.5rem;
          h3 {
            padding-bottom: 0.25rem;
            margin-bottom: 1.5rem;
            border-bottom: 1px solid $orange-bg;
          }
        }
      }
    }

    .footer {
      bottom: 0;
      width: 90%;
      padding-top: 2%;
    }
  }
  .close {
    float: right;
    margin: 0;
    padding: 0.5em 1em;
  }

  .receive_marketing_emails {
    label {
      margin-bottom: 1rem;
    }

    input {
      margin: 0 0.5rem 0 0;
    }
  }
}

#fade {
  position: fixed;
  left: 0;
  top: 0;
  background-color: black;
  -moz-opacity: 0.7;
  opacity: 0.7;
  filter: alpha(opacity=70);
  width: 100%;
  height: 100%;
  z-index: $modal-z-index;
}

/** Product Location styles **/

#modal {
  .section {
    clear: both;
    border-bottom: 1px solid black;
    padding: 1rem;

    .image,
    .content {
      display: inline-block;
      padding: 0 2rem;
    }

    h3 {
    }
    p {
    }

    &.not-available {
      opacity: 0.5;
    }
  }
}

.modal-open, .iframe-modal {
  overflow: hidden;
}

.form.ng-invalid.ng-dirty input.ng-invalid {
  border-color: $red;
}

@media #{$large-up} {
  .profile-modal {
    .fields-container {
      .input-container {
        display: flex;

        label {
          flex-basis: 25%;
        }
      }
    }
  }
}
