@import '../../../angular/scss/settings/sizes';
@import '../../../angular/scss/settings/zindex';
@import '../mixins/layout';;
@import '../../../angular/scss/mixins/buttons';

@media #{$large-up} {
  .wrap {
    max-width: $max-desktop-width;
    margin-right: auto;
    margin-left: auto;
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;

    .left-panel {
      max-height: 0;
      overflow: hidden;
      display: block;
      position: absolute;
      width: 100%;
      max-width: $max-desktop-width;
      z-index: $dropdown-z-index;

      .menu-container {
        display: flex;
        align-items: center;
        @include listNavigation;

   
      }
    }
  }

  .menu-section {
    .right-panel-button {
      @include button-style($orange-bg, false, false, $orange-bg, null);
      > span {
        @include button-base;
      }
    }
  }

  .iframe-modal:not(.fixed-top) {
    .menu-section,
    .top-nav {
      z-index: -9999999;
    }
  }

  .right-panel {
    max-height: 0;
    overflow: hidden;
    display: block;
    position: absolute;
    top: $desktop-nav-height;
    width: 30%;
    z-index: $dropdown-z-index;
    right: 0;

    nav {
      padding-top: 1em;
    }

    li {
      list-style: none;
      &.divider {
        border-bottom: 1px solid $oc-parent-list-colour;
        margin-right: 1.1rem;
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;
      }
    }
  }

  .active-right-panel {

    .right-panel {
      border: 3px solid #ffa300;
      max-height: 1000px;
    }
  }

  .active-left-panel {
    .left-panel {
      max-height: 1000px;
    }
  }

  .left-panel,
  .right-panel {
    @include transition(max-height);
  }

  .hide-desktop {
    display: none !important;
  }

  .content {
    margin-bottom: $desktop-nav-margin + $desktop-nav-height;
    @include clearfix;
    flex: 1;
  }

  .guest {
    height: 100%;
    > .content {
      margin: 0;
      max-width: $max-desktop-width;
      width: 100%;
      flex: 1;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }

    .social-icons {
      display: none;
    }
  }
}

.social-icons {

  &.mobile {
    display: none;
  }
  &.hide-mobile {
    position: absolute;
    left: 120px;
    top: 25%;

    a {
      &:nth-of-type(3) {
        padding: 0 7px;
      }
    }
  }
 
  display: flex;
  a {
    color: white;
  }
}