@import '../../../angular/scss/mixins/login';

.wrap {
  &.guest {
    @include clearfix;

    .modal {
      display: none !important;
    }
    .btn {
      margin-bottom: 0;
    }
    .forgotten-link {
      color: $black;
      flex: 1;
      display: flex;
      align-items: end;
    }

    @media #{$media-mobile} {
      background: white;

      .brand {
        background: $orange-bg;
      }

      .login-panel {
        @include login-panel(false);
      }
    }
    
    @media #{$large-up} {
      background: white url('../images/login-background-clear.png') repeat-x;
      background-position-y: 50%;
      min-height: 100%;
      background-size: contain;

      .flex-login-container {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-right: 50px;
        .login-panel {
          @include login-panel(true);
        }
      }

      .flex-forgotten-container {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        .login-panel {
          flex: 1;
        }
      }


      .left-panel {
        position: absolute;
        top: 1em;
        right: 1em;
        max-height: 1000px;
        left: auto;
        background: transparent;
        width: auto;
        border: 0;

        nav {
          -webkit-column-count: auto; /* Chrome, Safari, Opera */
          -moz-column-count: auto; /* Firefox */
          column-count: auto;
        }

        ul {
          list-style: none;

          li {
            margin-left: 2em;
            border: 0;
            display: inline-block;
            width: auto;

            a {
              color: $orange-bg;
            }
          }
        }
      }
    }

    .register-panel,
    .forgotten-panel,
    .reset-panel,
    .login-form-panel {
      @include fullSizePanel;
    }
  }
}
