$fa-font-path: '~font-awesome/fonts' !default;
@import '~font-awesome/scss/variables';
@import '~font-awesome/scss/mixins';
// @import '~font-awesome/scss/path';
@font-face {
  font-family: 'FontAwesome';
  font-display: block;
  src: url('#{$fa-font-path}/fontawesome-webfont.eot?v=#{$fa-version}');
  src: url('#{$fa-font-path}/fontawesome-webfont.eot?#iefix&v=#{$fa-version}')
      format('embedded-opentype'),
    url('#{$fa-font-path}/fontawesome-webfont.woff2?v=#{$fa-version}')
      format('woff2'),
    url('#{$fa-font-path}/fontawesome-webfont.woff?v=#{$fa-version}')
      format('woff'),
    url('#{$fa-font-path}/fontawesome-webfont.ttf?v=#{$fa-version}')
      format('truetype'),
    url('#{$fa-font-path}/fontawesome-webfont.svg?v=#{$fa-version}#fontawesomeregular')
      format('svg');
  //  src: url('#{$fa-font-path}/FontAwesome.otf') format('opentype'); // used when developing fonts
  font-weight: normal;
  font-style: normal;
}
@import '~font-awesome/scss/core';
@import '~font-awesome/scss/larger';
@import '~font-awesome/scss/fixed-width';
@import '~font-awesome/scss/list';
@import '~font-awesome/scss/bordered-pulled';
@import '~font-awesome/scss/animated';
@import '~font-awesome/scss/rotated-flipped';
@import '~font-awesome/scss/stacked';
@import '~font-awesome/scss/icons';
@import '~font-awesome/scss/screen-reader';
