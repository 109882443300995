$help-z-index: 999998;

$message-z-index: $help-z-index + 5;

$loading-z-index: $help-z-index + 4;
$modal-z-index: $help-z-index + 3;
$overlay-z-index: $help-z-index + 2;
$dropdown-z-index: $help-z-index - 2;

$nav-z-index: $help-z-index - 1;
$issue-modal-z-index: $dropdown-z-index - 1;
$widget-z-index: $help-z-index - 3;
