@import '../settings/sizes';
@import '../settings/colours';
@import '../mixins/off-canvas';
@media #{$media-mobile} {
  body {
    padding: 0;
    margin: 0;
  }

  .wrap {
    width: 100%;
    min-height: 100%;
    overflow-x: hidden;

    .left-panel {
      .menu-container {
        display: flex;
        flex-direction: column;
        .social-icons {
          &.mobile {
            display: flex;
            border-bottom: 2px solid $orange-bg;
            padding: 15px;

            a {
              color: $orange-bg;
              &:hover {
                background-color: transparent;
              }
              &:nth-of-type(2) {
                padding-left: 10px;
                padding-right: 10px;
              }
            }
          }
        }
      }
    }
  }

  .brand {
    display: none;
  }

  .logged-in-footer {
    .bottom-nav {
      background: $topbar-link-bg;

      ul {
        li {
          a {
            color: white;
          }
        }
      }
    }
  }

  .top-nav {
    @include mobile-nav;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    z-index: 0;
    overflow: visible;

    .mobile-logo {
      flex: 1;
      margin: 5px 0;

      &.register {
        margin-right: 48px;
      }
    }
  }

  .top-nav-widget {
    display: none;
  }

  .content {
    position: relative;
    min-height: 100%;
  }

  [data-ui-view='main'] {
    width: 100%;
    // padding-top: $mobile-nav-height; // + $mobile-nav-margin;
    padding-bottom: $mobile-nav-height; // + $mobile-nav-margin;
    // @include clearfix();
  }

  .bottom-nav {
    @include mobile-nav;
  }

  @include offcanvas();

  @media #{$small-only} {
    .hide-small-mobile {
      display: none !important;
    }

    @include offcanvas-size($small-offcanvas-size);
  }

  @media #{$medium-only} {
    @include offcanvas-size($medium-offcanvas-size);
  }

  .hide-mobile {
    display: none !important;
  }
}