@import 'foundation-sites/scss/foundation/functions';
@import './colours';
$column-gutter: rem-calc(30);

$small-range: (0em, 40em);
$medium-range: (40.0001em, 66em);
$large-range: (66.0001em, 90em);
$xlarge-range: (90.063em, 120em);

$topbar-height: 75px;
$topbar-margin-bottom: 0;

$screen: 'only screen';

$mobile-only: '#{$screen} and (max-width:#{lower-bound($medium-range)})' !default;
$media-mobile: '#{$screen} and (max-width:#{upper-bound($medium-range)})' !default;
$medium-up: '#{$screen} and (min-width:#{lower-bound($medium-range)})';
$small-only: "#{$screen} and (max-width: #{upper-bound($small-range)})";
$medium-only: "#{$screen} and (min-width:#{lower-bound($medium-range)}) and (max-width:#{upper-bound($medium-range)})";

$medium-down: '#{$screen} and (max-width:#{upper-bound($medium-range)})';
$large-up: '#{$screen} and (min-width:#{lower-bound($large-range)})';

$media-desktop: #{$large-up};
$large-only: '#{$screen} and (min-width:#{lower-bound($large-range)}) and (max-width:#{upper-bound($large-range)})';
$xlarge-up: '#{$screen} and (min-width:#{lower-bound($xlarge-range)})';

$max-desktop-width: 1200px;
$desktop-brand-height: 130px;
$desktop-nav-height: $topbar-height;
$desktop-nav-margin: 20px;

$mobile-brand-height: 130px;
$mobile-nav-height: $topbar-height;
$mobile-nav-margin: 20px;

$small-offcanvas-size: 80%;
$medium-offcanvas-size: 50%;

$transition-time: 0.35s;
$transition-effect: ease-in-out;

$reveal-box-shadow: 0 0 10px rgba($black, 0.4);

$off-canvas-link-padding: rem-calc(10, 15);
