@mixin base-nav {
    height: $topbar-height;
    line-height: $topbar-height;
    // background: $topbar-link-bg;
  
    ul {
      height: $topbar-height;
      margin: 0;
      li {
        line-height: $topbar-height;
        height: $topbar-height;
        width: $topbar-height;
        color: $orange-bg;
        display: block;
        margin: 0;
        &:hover {
          background-color: $topbar-link-bg-hover;
          @if ($topbar-link-bg-hover) {
            background: $topbar-link-bg-hover;
          }
          color: $topbar-link-color-hover;
          cursor: pointer;
        }
  
        & > * {
          width: $topbar-height;
          text-align: center;
        }
      }
    }
    z-index: $nav-z-index;
  }
  
  @mixin mobile-nav {
    @include base-nav();
  
    bottom: 0;
    left: 0;
    right: 0;
    overflow: hidden;
  }
  
  @mixin desktop-nav {
    @include base-nav();
  
    max-width: $max-desktop-width;
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    bottom: 0;
  }
  