@import '../../../angular/scss/settings/colours';

.grid-layout {
  display: flex;
  flex-wrap: wrap;

  &.mind {
    .category-image-header {
      .title {
        background: $mind-primary-bg;
      }
    }
  }

  &.body {
    .category-image-header {
      .title {
        background: $body-primary-bg;
      }
    }
  }

  &.life {
    .category-image-header {
      .title {
        background: $life-primary-bg;
      }
    }
  }

  &.soul {
    .category-image-header {
      .title {
        background: $soul-primary-bg;
      }
    }
  }
  .category-image-header {
    @include clearfix;

    > div {
      @include grid-column($columns: 12, $collapse: true);
    }

    .image {
      height: 140px;

      img {
        width: 100%;
        height: 140px;
        object-fit: cover;
      }
    }

    .title {
      // full width && orange
      background: $orange-bg;
      h1 {
        text-align: center;
        color: $white;
      }
    }
    .description {
      @include grid-column($columns: 12);

      @media #{$medium-up} {
        @include grid-column($columns: 8, $offset: 2, $collapse: true);

        p {
          font-size: 1.1rem;
        }
      }
    }
  }
}
