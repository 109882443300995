// OFF CANVAS LIST
// This is the list of links in the off-canvas menu
@import '../mixins/transition';

@mixin off-canvas-list() {
  list-style-type: none;
  padding: 0;
  margin: 0;

  li {
    a {
      display: block;
      border-bottom: $orange-bg;
      &:hover {
        background: scale-color($tabbar-bg, $lightness: -20%);
      }
      transition: background 300ms ease;
    }
  }
}

@mixin list-color($color) {
  li {
    label,
    a {
      color: $color;

      span {
        color: $orange-bg;
      }
    }
  }
}

@mixin offcanvas-size($size) {
  .left-panel {
    margin-left: -$size;
    left: 0;
  }
  .right-panel {
    margin-right: -$size;
    right: 0;
  }

  .left-panel,
  .right-panel {
    width: $size;
  }
}

@mixin offcanvas() {
  .top-panel,
  .bottom-panel,
  .left-panel,
  .right-panel {
    position: fixed;
    overflow-y: auto;
    overflow-x: hidden;
    z-index: $widget-z-index;

    ul {
      //colours
      @include off-canvas-list;
    }
  }

  .top-panel,
  .bottom-panel {
    left: 0;
    right: 0;

    .row {
      padding: 1em;
    }

    max-height: 0;

    //    @include transition("max-height, padding", "0.35, 0.35");
    //    transition-delay: 0, 2s;
    @include transition(max-height);
  }

  .top-panel {
    top: $mobile-nav-height;
  }

  .bottom-panel {
    bottom: $mobile-nav-height;
  }

  .active-top-panel .top-panel,
  .active-bottom-panel .bottom-panel {
    max-height: 50%;
  }

  .active-left-panel .left-panel {
    margin-left: 0;
  }

  .active-right-panel .right-panel {
    margin-right: 0;
    border-left: 3px solid #ffa300;
    border-bottom: 3px solid #ffa300;

  }

  .left-panel,
  .right-panel {
    @include transition(margin);

    top: $mobile-nav-height;
    bottom: $mobile-nav-height;
  }

  .overlay {
    position: fixed;
    background-color: $oc-overlay-colour;

    opacity: 0;
    top: 0;
    bottom: 100%;
    left: 0;
    right: 0;
    z-index: $widget-z-index - 1;
    @include transition(opacity);
  }
  .active-left-panel,
  .active-right-panel,
  .active-top-panel,
  .active-bottom-panel {
    .overlay {
      bottom: 0;
      opacity: $oc-overlay-opacity;
    }
  }
}
