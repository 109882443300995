@mixin listNavigation($columns: 3) {
  nav {
    -webkit-column-count: $columns; /* Chrome, Safari, Opera */
    -moz-column-count: $columns; /* Firefox */
    column-count: $columns;
    width: 60%;
  }

  ul {
    margin: 0;
  }

  li {
    list-style: none;
    line-height: #{1.5 * 2}rem;
    -webkit-column-break-inside: avoid; /* Chrome, Safari, Opera */
    page-break-inside: avoid; /* Firefox */
    break-inside: avoid; /* IE 10+ */
  }
  li li {
    border: 0;
    line-height: 1.5rem;
  }
}
