/*******************************************************************************
 * DO NOT EDIT THIS FILE!
 *
 * This file is managed by Augeo/Composer - make changes there, not in project
 ******************************************************************************/

[ng\:cloak],
[ng-cloak],
[data-ng-cloak],
[x-ng-cloak],
.ng-cloak,
.x-ng-cloak,
.ng-hide:not(.ng-hide-animate) {
  display: none !important;
}

[ng\:form],
[ng-form],
[data-ng-form],
.ng-form,
.x-ng-form {
  display: block;
}

.ng-animate-shim {
  visibility: hidden;
}

.ng-anchor {
  position: absolute;
}

/* To set the cursor for ngClick */
[ng\:click],
[ng-click],
[data-ng-click],
[x-ng-click],
[data-ui-sref],
.ng-click,
.x-ng-click {
  cursor: pointer;
}

.ng-valid {
  //border-color: $success-color !important;
}

.ng-invalid.ng-dirty {
  border-color: $alert-color !important;
}
