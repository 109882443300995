$orange: #f1b472;
$white: #ffffff;
$black: #000000;
$lighten: 10%;
$blue-bg: rgb(28, 47, 63);
$jlp-orange: #d4760a;
$orange-bg: #ffa300;
$orange-bg-hover: #ca7d25;
$orange-light-bg: #fbe4c9;
$orange-mid-bg: lighten($orange-bg, $lighten);
$orange-dark-bg: darken($orange-bg, $lighten);
$orange-overlay: rgba(244, 144, 29, 0.7);
$blue-overlay: rgba(33, 45, 58, 0.7);
$green: rgb(59, 152, 59);
$red: #ff3334;
$charcoal: #555555;


// mind scheme
$mind-primary-bg: #9999cc;
$mind-light-bg: #ccccff;

// soul scheme
$soul-primary-bg: #ffcc99;
$soul-light-bg: #ffcc33;

// body scheme
$body-primary-bg: #ff9999;
$body-light-bg: #ffcccc;

// life scheme
$life-primary-bg: #66cc33;
$life-light-bg: #99cc99;

$blue-menu-text: #7ccad1;
$green-menu-text: #70b86e;
$purple-menu-text: #b386af;
$orange-menu-text: $orange-bg;
$grey-menu-text: #666666;

$overlay-colour: $white;
$overlay-opacity: 0.5;

$oc-panel-bg: $white;
$oc-parent-list-colour: $black;
$oc-child-list-colour: $orange-menu-text;
$oc-overlay-colour: $overlay-colour;
$oc-overlay-opacity: $overlay-opacity;

$alert-color: $red;

$topbar-link-color: $white;
$topbar-link-color-hover: $white;
$topbar-link-bg: $orange-bg;
$topbar-link-bg-color-hover: $charcoal;
$topbar-link-bg-hover: $orange-bg-hover;

$tabbar-bg: $blue-bg;
$tabbar-color: $white;
