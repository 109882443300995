@import '../../../angular/scss/mixins/list';

.my-orders,
.my-bookings,
.my-clubs {
  .item {
    @include listItem();
  }

  .account-row {
    margin-top: 10px;
    margin-bottom: 10px;

    a {
      color: black;

      span {
        color: $orange-bg;
      }
    }
  }

  .header-row {
    display: flex;

    @media (max-width: 768px) {
      flex-direction: column;
    }
    h2 {
      flex: 1;
    }

    span {
      margin-bottom: 10px;
    }
  }
}
