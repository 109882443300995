@mixin transition($type, $time: $transition-time, $effect: $transition-effect) {
  -webkit-transition: $type $time $effect;
  -moz-transition: $type $time $effect;
  -o-transition: $type $time $effect;
  transition: $type $time $effect;
}

@mixin rounded($value) {
  -webkit-border-radius: $value;
  -moz-border-radius: $value;
  border-radius: $value;
}

@mixin transform($prop) {
  -webkit-transition: $prop;
  -moz-transition: $prop;
  -ms-transition: $prop;
  -o-transition: $prop;
  transition: $prop;
}
