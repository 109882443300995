@mixin login-panel($lg-screen: true) {
  @if $lg-screen == true {
    @include center-login-panel;
    @include side-by-side;
  } @else {
    margin: 1em;
  }

  padding: 0;

  .login-container {
    width: 100%;
    background-color: $orange-bg;
    padding: 10px;
    display: flex;
    flex-direction: row-reverse;
    flex-wrap: wrap;
  }

  h2,
  button,
  p,
  h5,
  label {
    font-weight: 300;
  }
  .login-form-panel,
  .register-panel {
    flex-basis: 100%;
    @media #{$large-up} {
      flex-basis: 50%;
    }

    .accordion-form {
      overflow-x: scroll;
      height: 100%;
    }

    .contents {
      padding: 1.5rem;
      height: 100%;
      display: flex;
      flex-direction: column;

      button {
        margin: 0;
      }
      form {
        display: flex;
        flex-direction: column;
        height: 100%;
      }

      .text-container {
        flex: 1;
        display: flex;
        flex-direction: column;
        p {
          &:nth-of-type(1) {
            flex: 1;
          }
          a {
            color: white;
          }
        }
      }

      .main-text {
        flex-basis: 100%;
      }

      .bottom-text {
        margin-top: 1.25rem;
        margin-bottom: 0;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
      }

      button {
        width: 100%;
      }
    }
  }

  .login-form-panel {
    background: white;
    h2 {
      color: $black;
    }

    input {
      border-color: $orange-bg;
      border-width: 3px;
    }

    label {
      color: $black;
    }
  }

  &.flex-forgotten {
    height: 100%;
  }

  .register-panel,
  .forgotten-panel {
    h2,
    h5,
    p {
      color: white;
    }

    h5 {
      margin-top: 0;
    }

    h2 {
      margin-bottom: 0;
    }

    [for='receive_marketing_emails'] {
      margin-bottom: 1rem;
    }

    input#receive_marketing_emails {
      margin: 0 0.5rem 0 0;
    }
  }

  h2 {
    font-size: 28px;
    line-height: 40px;
  }
}

@mixin center-login-panel {
  position: relative;
  width: 850px;
  max-height: 75%;
  background-color: $orange-bg;
  display: flex;
}

@mixin side-by-side {
  .login-form-panel,
  .register-panel {
    position: relative;
    float: right;
    height: 100%;
  }
}

@mixin fullSizePanel() {
  &.full {
    width: 100%;
    background: $orange-light-bg;
    padding: 2em 0;

    h2 {
      color: $black;
      padding: 0 1rem;
      border-bottom: 1px solid $orange-dark-bg;

      span {
        font-size: 1rem;
        float: right;
      }
    }

    p {
      color: $black;
    }

    button,
    .button {
      position: relative;
      bottom: auto;
    }

    .panel-accordion {
      .accordion-navigation {
        .content {
          background-color: white !important;
        }
        .next {
          background: $orange-mid-bg;
          margin: 0 1rem;

          &.active {
            background: $orange-dark-bg;
          }
        }
      }

      .content {
        margin: 0;
      }
    }
  }
}
