@import '../settings/colours';
@import '../settings/sizes';
@import '../mixins/_buttons.scss';

@mixin listItem($scheme: 'default') {
  max-width: 100%;
  min-width: 100%;
  padding: 1em;
  display: flex;

  &.refunded {
    opacity: 0.5;
  }

  .item-contents {
    border: 1px solid $blue-bg;
    border-radius: 5px;
    padding: 0;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    background: $white;
    flex: 1;

    .image {
      height: 160px;
      border-radius: 5px 5px 0 0;
      position: relative;
      background-position: center;
      background-size: cover;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      .badged {
        position: absolute;
        right: 0;
        top: 0;
        background: #ffffff;
        color: #d4760a;
        border: 2px solid #d4760a;
        border-radius: 50%;
        font-weight: 700;
        text-transform: capitalize;
        width: 7em;
        height: 7em;
        line-height: 7em;
        transform: translate(15%, -15%);
        text-align: center;
      }
    }

    .description {
      position: relative;
      min-width: 0;
      padding: 1rem;
      display: flex;
      height: 100px;
      flex-direction: column;
      justify-content: space-between;
      flex: 1;

      h3 {
        min-width: 0;
        font-size: 1.2em;
        text-overflow: ellipsis;
        overflow: hidden;
        max-width: 100%;
        display: block;
      }

      .item-description {
        height: 3em;
        margin-bottom: 0;
        overflow: hidden;
      }
    }

    .item-price {
      align-items: flex-end;
      padding: 1rem;
      background-color: $orange-bg;

      @if $scheme == 'body' {
        background-color: $body-primary-bg;
      }

      @if $scheme == 'mind' {
        background-color: $mind-primary-bg;
      }

      @if $scheme == 'life' {
        background-color: $life-primary-bg;
      }

      @if $scheme == 'soul' {
        background-color: $soul-light-bg;
      }


      border-radius: 0 0 5px 5px;
      color: $black;
      position: relative;

      &.expired {
        background-color: lighten(red, 10);
      }

      p {
        margin: 0;
        width: 100%;
        span {
          display: block;
          font-size: 1.8rem;
          height: 1.8em;

          &.btn {
            @include jlpButton;
          }

          &.large,
          &.small,
          &.full {
            text-align: center;
            display: inline-block;
          }

          &.large {
            width: 78%;
          }

          &.small {
            width: 20%;
          }

          &.full {
            width: 100%;
          }
        }
      }

      .view-details {
        display: none;
      }
    }
  }

  @media #{$medium-up} {
    max-width: calc(100% / 2);
    min-width: calc(100% / 2);
  }

  @media #{$large-up} {
    max-width: calc(100% / 3);
    min-width: calc(100% / 3);
  }

  @media #{$xlarge-up} {
    max-width: calc(100% / 4);
    min-width: calc(100% / 3);
  }
}
