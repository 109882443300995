@import '~@angular/material/prebuilt-themes/indigo-pink.css';
@import '../angular/scss/settings/colours';

@import 'settings/foundation';
@import 'angular';
// @import "normalize";
@import '../angular/scss/settings/font-awesome';
// @import '~font-awesome/scss/font-awesome';

@import 'foundation-sites/scss/foundation/components/buttons';
@import 'foundation-sites/scss/foundation/components/inline-lists';
@import 'foundation-sites/scss/foundation/components/grid';
@import 'foundation-sites/scss/foundation/components/alert-boxes';
@import 'foundation-sites/scss/foundation/components/tabs';
@import 'foundation-sites/scss/foundation/components/forms';
@import 'foundation-sites/scss/foundation/components/split-buttons';
@import 'foundation-sites/scss/foundation/components/dropdown';
@import 'foundation-sites/scss/foundation/components/panels';
@import 'foundation-sites/scss/foundation/components/reveal';
@import 'foundation-sites/scss/foundation/components/visibility';
@import 'foundation-sites/scss/foundation/components/global';
@import 'foundation-sites/scss/foundation/components/type';
@import 'foundation-sites/scss/foundation/components/forms';
@import 'foundation-sites/scss/foundation/components/tooltips';
@import 'foundation-sites/scss/foundation/components/accordion';
@import 'foundation-sites/scss/foundation/components/switches';

//@import "owl.carousel";

@import '../angular/scss/settings/zindex';
@import '../angular/scss/settings/sizes';

// leisure mixins - layout
@import 'leisure/mixins/layout';


// leisure components
@import '../angular/scss/mixins/navigation';
@import 'leisure/components/login-screen';
@import '../angular/scss/components/grid';
@import '../angular/scss/components/mobile-layout';
@import 'leisure/components/desktop-layout';
@import 'leisure/components/modal';
@import 'leisure/components/my-ballots';


* {
  outline: none;
}

.top-panel button,
.top-panel a {
  margin-bottom: 0;
}


button,
.button {
  //@include transition(all);
  &.inactive {
    background: $grey-menu-text;
    color: $orange-bg;
  }
}

.max-width {
  width: 100%;
  max-width: 100%;
}


.download {
  @extend .text-center;
  font-size: 1.2rem;
  line-height: 2em;
  span {
    @extend .fa;
    @extend .fa-file;
  }
}



h2 {
  span.key {
    font-size: 0.5em;
  }
}



ul.address {
  margin-left: 0;
}

